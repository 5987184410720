<template>
  <section id="AllegiantStadium">
    <div class="creditOneOverview__background"></div>
    <div class="mobile-overlay mobile"></div>
    <Overview :overviewData="overviewData" />
    <AllegiantStadiumMobile class="mobile" />
  </section>
</template>

<script>
import Overview from '@/components/global/Overview'
import { getOverviewData } from '@/data/sectionData/eventSpaces/AllegiantStadium'
import AllegiantStadiumMobile from '@/components/sections/EventSpaces/AllegiantStadium/AllegiantStadiumMobile'

export default {
  name: "AllegiantStadiumOverview",
  components: {
    Overview,
    AllegiantStadiumMobile
  },
  data() {
    return {
      overviewData: getOverviewData()
    }
  }
};
</script>

<style lang="scss" scoped>
  #AllegiantStadium {
    width: 100%;
    position: relative;
    @media (max-width: 993px) {
      position: relative;
      width: 100vw;
      min-height: 100vh;
      background-image: url('~@/assets/images/MobileBG/Allegiant-Stadium-BG.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
    }
    .creditOneOverview__background {
      background-image: url('~@/assets/images/EventSpaces/allegiant-stadium-bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -10;
      @media (max-width: $lg) {
        display: none;
      }
    }
    .mobile-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: auto;
      width: 100%;
      background-image: linear-gradient(90deg, rgba(0,0,0,0.80) 48%, rgba(0,0,0,0.72) 88%);
    }
    .desktop {
      @media (max-width: $lg) {
        display: none;
      }
    }
    .mobile {
      position: relative;
      @media (min-width: 993px) {
        display: none;
      }
    }
  }
</style>